import React, { useEffect, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/autohub.css";
import "./css/track-progress.css";
import "./css/responsiveness.css";
import "./components/scheduleAppointmentPage/addonsFlow/addons.scss";
import "./components/estimationRequest/styles/estimation.css";
import "./components/estimationRequest/styles/responsive.css";

const Home = lazy(() => import("./components/homePage"));
const Location = lazy(() => import("./components/locationPage"));
const Offers = lazy(() => import("./components/offersPage"));
const OffersDetail = lazy(() => import("./components/offersPage/offersDetail"));
const Kaizen = lazy(() => import("./components/kaizenPage"));
const KaizenDetail = lazy(() => import("./components/kaizenPage/kaizenDetails"));
const BringItToTheExpert = lazy(() => import("./components/bringItToTheExpertPage"));
const ScheduleAppointment = lazy(() => import("./components/scheduleAppointmentPage"));
const FastDrop = lazy(() => import("./components/scheduleAppointmentPage/expressFlow"));
const WelcomeEstimateScreen = lazy(() => import("./components/estimationRequest/WelcomeScreen"));
const EstimationRequest = lazy(() => import("./components/estimationRequest"));
const ContactUs = lazy(() => import("./components/contactUsPage"));
const ServiceDetail = lazy(() => import("./components/servicesPage/servicesDetatil"));
const AccessibilityPolicy = lazy(() => import("./components/accessibilityPolicyPage"));
const PrivacyPolicy = lazy(() => import("./components/privacyPolicyPage"));
const TermsAndConditions = lazy(() => import("./components/termsAndConditions"));
const RequiredDocuments = lazy(() => import("./components/requiredDocumentsPage"));
const SiteMap = lazy(() => import("./components/siteMapPage"));
const FindUs = lazy(() => import("./components/FindusPage"));
const Services = lazy(() => import("./components/servicesPage"));
const TrackYourRepairProgress = lazy(() => import("./components/trackYourRepairPage"));
const CookiePolicy = lazy(() => import("./components/cookiePolicy"));
const MyAccount = lazy(() => import("./components/myAccount"));
const EstimateTermsAndConditions = lazy(() => import("./components/estimateTerms&Conditions"));
const Blog = lazy(() => import("./components/blogs"));
const BlogDetails = lazy(() => import("./components/blogs/BlogDetails"));
const QuotationDetails = lazy(() => import("./components/getAQuote/QuotationDetails"));
const RequestAQuote = lazy(() => import("./components/getAQuote/RequestAQuote"));
const ProductsListing = lazy(() => import("./components/eShop/ProductsListing"));
const ProductDetails = lazy(() => import("./components/eShop/ProductDetails"));
const Subscription = lazy(() => import("./components/subscription"));

import { ToastContainer } from "react-toastify";
import {
  Header,
  Footer,
  OrdersCheckout,
  Loader,
  SuccessScreen,
  PrivateRoute
} from "./components/shared";
import {
  CNDBookingDocsAlert,
  NotFound,
  PasswordModal,
  GoToTopButton
} from "./components/utilityComponents";

import { allowedDomains, domainOrigin, isAccessComponent } from "./util/constants";
import { internetDetector } from "./util/common";
import NewPrivacyPolicyPage from "./components/newPrivacyPolicyPage";
import OnlineContest from "./components/onlineContest";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Handling PWA cache and will delete cache only for website
    if (!window.matchMedia("(display-mode: standalone)").matches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      0;
    }

    internetDetector();
  }, []);

  return (
    <>
      <Router>
        <Suspense fallback={<Loader show={true} />}>
          {isAccessComponent && <Header />}

          <Routes>
            <Route path="/en" element={<PrivateRoute element={Home} accessKey="home" />} />
            <Route path="/" element={<PrivateRoute element={Home} accessKey="home" />} />
            <Route
              path="/en/locations"
              element={<PrivateRoute element={Location} accessKey="location" />}
            />
            <Route
              path="/locations"
              element={<PrivateRoute element={Location} accessKey="location" />}
            />
            <Route
              path="/en/services"
              element={<PrivateRoute element={Services} accessKey="services" />}
            />
            <Route
              path="/services"
              element={<PrivateRoute element={Services} accessKey="services" />}
            />
            <Route
              path="/en/select-service"
              element={<PrivateRoute element={Services} accessKey="services" />}
            />
            <Route
              path="/select-service"
              element={<PrivateRoute element={Services} accessKey="services" />}
            />
            <Route
              path="/en/services/:id"
              element={<PrivateRoute element={ServiceDetail} accessKey="services" />}
            />
            <Route
              path="/services/:id"
              element={<PrivateRoute element={ServiceDetail} accessKey="services" />}
            />
            <Route
              path="/en/offers"
              element={<PrivateRoute element={Offers} accessKey="offers" />}
            />
            <Route path="/offers" element={<PrivateRoute element={Offers} accessKey="offers" />} />
            <Route
              path="/en/offers/:slug"
              element={<PrivateRoute element={OffersDetail} accessKey="offers" />}
            />
            <Route
              path="/offers/:slug"
              element={<PrivateRoute element={OffersDetail} accessKey="offers" />}
            />
            <Route
              path="/en/subscription/:slug"
              element={<PrivateRoute element={OffersDetail} accessKey="offers" />}
            />
            <Route
              path="/subscription/:slug"
              element={<PrivateRoute element={OffersDetail} accessKey="offers" />}
            />
            <Route
              path="/en/subscribe/:slug"
              element={<PrivateRoute element={Subscription} accessKey="offers" />}
            />
            <Route
              path="/subscribe/:slug"
              element={<PrivateRoute element={Subscription} accessKey="offers" />}
            />
            <Route
              path="/en/kaizen"
              element={<PrivateRoute element={Kaizen} accessKey="kaizen" />}
            />
            <Route path="/kaizen" element={<PrivateRoute element={Kaizen} accessKey="kaizen" />} />
            <Route
              path="/en/kaizen/:id"
              element={<PrivateRoute element={KaizenDetail} accessKey="kaizen" />}
            />
            <Route
              path="/kaizen/:id"
              element={<PrivateRoute element={KaizenDetail} accessKey="kaizen" />}
            />
            <Route
              path="/en/bring-it-to-the-expert"
              element={<PrivateRoute element={BringItToTheExpert} accessKey="schedule" />}
            />
            <Route
              path="/bring-it-to-the-expert"
              element={<PrivateRoute element={BringItToTheExpert} accessKey="schedule" />}
            />
            <Route
              path="/en/track-your-repair-progress"
              element={<PrivateRoute element={TrackYourRepairProgress} accessKey="track" />}
            />
            <Route
              path="/track-your-repair-progress"
              element={<PrivateRoute element={TrackYourRepairProgress} accessKey="track" />}
            />
            <Route
              path="/en/my-account/:id?/:subscriptionId?"
              element={<PrivateRoute element={MyAccount} accessKey="myAccount" />}
            />
            <Route
              path="/my-account/:id?/:subscriptionId?"
              element={<PrivateRoute element={MyAccount} accessKey="myAccount" />}
            />
            <Route
              path="/en/schedule-appointment/:id?"
              element={<PrivateRoute element={ScheduleAppointment} accessKey="schedule" />}
            />
            <Route
              path="/schedule-appointment/:id?"
              element={<PrivateRoute element={ScheduleAppointment} accessKey="schedule" />}
            />
            <Route
              path="/en/appointment/fast-drop"
              element={<PrivateRoute element={FastDrop} accessKey="schedule" />}
            />
            <Route
              path="/appointment/fast-drop"
              element={<PrivateRoute element={FastDrop} accessKey="schedule" />}
            />
            <Route
              path="/en/repair-estimate"
              element={<PrivateRoute element={WelcomeEstimateScreen} accessKey="estimation" />}
            />
            <Route
              path="/repair-estimate"
              element={<PrivateRoute element={WelcomeEstimateScreen} accessKey="estimation" />}
            />
            <Route
              path="/en/repair-estimate/request/:id?"
              element={<PrivateRoute element={EstimationRequest} accessKey="estimation" />}
            />
            <Route
              path="/repair-estimate/request/:id?"
              element={<PrivateRoute element={EstimationRequest} accessKey="estimation" />}
            />
            <Route
              path="/en/customer-care"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/customer-care"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/en/partner-with-autohub"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/partner-with-autohub"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/en/join-autohub-family"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/join-autohub-family"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            {/* <Route
              path="/en/delete-account"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            /> */}
            <Route
              path="/delete-account"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/en/signup-for-service"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/signup-for-service"
              element={<PrivateRoute element={ContactUs} accessKey="contactus" />}
            />
            <Route
              path="/en/findus"
              element={<PrivateRoute element={FindUs} accessKey="findUs" />}
            />
            <Route path="/findus" element={<PrivateRoute element={FindUs} accessKey="findUs" />} />
            <Route
              path="/en/accessibility-policy"
              element={
                <PrivateRoute element={AccessibilityPolicy} accessKey="accessibilityPolicy" />
              }
            />
            <Route
              path="/accessibility-policy"
              element={
                <PrivateRoute element={AccessibilityPolicy} accessKey="accessibilityPolicy" />
              }
            />
            <Route
              path="/en/privacy-policy"
              element={<PrivateRoute element={PrivacyPolicy} accessKey="privacyPolicy" />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivateRoute element={PrivacyPolicy} accessKey="privacyPolicy" />}
            />
            <Route
              path="/en/privacy-update"
              element={<PrivateRoute element={NewPrivacyPolicyPage} accessKey="privacyPolicy" />}
            />
            <Route
              path="/privacy-update"
              element={<PrivateRoute element={NewPrivacyPolicyPage} accessKey="privacyPolicy" />}
            />
            <Route
              path="/en/online-contest"
              element={<PrivateRoute element={OnlineContest} accessKey="onlineContest" />}
            />
            <Route
              path="/online-contest"
              element={<PrivateRoute element={OnlineContest} accessKey="onlineContest" />}
            />
            <Route
              path="/en/terms-and-conditions"
              element={<PrivateRoute element={TermsAndConditions} accessKey="termsAndConditions" />}
            />
            <Route
              path="/terms-and-conditions"
              element={<PrivateRoute element={TermsAndConditions} accessKey="termsAndConditions" />}
            />
            <Route
              path="/en/cookie-policy"
              element={<PrivateRoute element={CookiePolicy} accessKey="cookiePolicy" />}
            />
            <Route
              path="/cookie-policy"
              element={<PrivateRoute element={CookiePolicy} accessKey="cookiePolicy" />}
            />
            <Route
              path="/en/required-documents"
              element={<PrivateRoute element={RequiredDocuments} accessKey="requiredDocuments" />}
            />
            <Route
              path="/required-documents"
              element={<PrivateRoute element={RequiredDocuments} accessKey="requiredDocuments" />}
            />
            <Route
              path="/:lang(en|ar)/document"
              element={<PrivateRoute element={RequiredDocuments} accessKey="requiredDocuments" />}
            />
            <Route
              path="/en/estimation-terms-and-conditions"
              element={
                <PrivateRoute element={EstimateTermsAndConditions} accessKey="estimationTnC" />
              }
            />
            <Route
              path="/estimation-terms-and-conditions"
              element={
                <PrivateRoute element={EstimateTermsAndConditions} accessKey="estimationTnC" />
              }
            />
            <Route
              path="/en/sitemap"
              element={<PrivateRoute element={SiteMap} accessKey="sitemap" />}
            />
            <Route
              path="/sitemap"
              element={<PrivateRoute element={SiteMap} accessKey="sitemap" />}
            />
            <Route
              path="/en/knowledgehub"
              element={<PrivateRoute element={Blog} accessKey="blogs" />}
            />
            <Route
              path="/knowledgehub"
              element={<PrivateRoute element={Blog} accessKey="blogs" />}
            />
            <Route
              path="/en/knowledgehub/:slug"
              element={<PrivateRoute element={BlogDetails} accessKey="blogs" />}
            />
            <Route
              path="/knowledgehub/:slug"
              element={<PrivateRoute element={BlogDetails} accessKey="blogs" />}
            />
            <Route
              path="/en/quote-service/:slug"
              element={<PrivateRoute element={QuotationDetails} accessKey="quotation" />}
            />
            <Route
              path="/quote-service/:slug"
              element={<PrivateRoute element={QuotationDetails} accessKey="quotation" />}
            />
            <Route
              path="/en/request-a-quote"
              element={<PrivateRoute element={RequestAQuote} accessKey="quotation" />}
            />
            <Route
              path="/request-a-quote"
              element={<PrivateRoute element={RequestAQuote} accessKey="quotation" />}
            />
            <Route
              path="/en/request-a-quote/:slug"
              element={<PrivateRoute element={RequestAQuote} accessKey="quotation" />}
            />
            <Route
              path="/request-a-quote/:slug"
              element={<PrivateRoute element={RequestAQuote} accessKey="quotation" />}
            />
            <Route
              path="/en/shop/products"
              element={<PrivateRoute element={ProductsListing} accessKey="eshop" />}
            />
            <Route
              path="/shop/products"
              element={<PrivateRoute element={ProductsListing} accessKey="eshop" />}
            />
            <Route
              path="/en/shop/product/:variantId/:branchId"
              element={<PrivateRoute element={ProductDetails} accessKey="eshop" />}
            />
            <Route
              path="/shop/product/:variantId/:branchId"
              element={<PrivateRoute element={ProductDetails} accessKey="eshop" />}
            />
            <Route
              path="/en/shop/checkout"
              element={<PrivateRoute element={OrdersCheckout} accessKey="eshop" />}
            />
            <Route
              path="/shop/checkout"
              element={<PrivateRoute element={OrdersCheckout} accessKey="eshop" />}
            />
            <Route
              path="/en/success"
              element={<PrivateRoute element={SuccessScreen} accessKey="other" />}
            />
            <Route
              path="/success"
              element={<PrivateRoute element={SuccessScreen} accessKey="other" />}
            />
            <Route path="/en/404" element={<PrivateRoute element={NotFound} accessKey="other" />} />
            <Route path="/404" element={<PrivateRoute element={NotFound} accessKey="other" />} />

            {/* Redirection routes */}
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>

          {isAccessComponent && <Footer />}
          {/* {isAccessComponent && <AppCookies />} */}
          <CNDBookingDocsAlert />
        </Suspense>
      </Router>

      <GoToTopButton />

      {!allowedDomains.includes(domainOrigin) && <PasswordModal />}

      {isAccessComponent && (
        <>
          <ToastContainer newestOnTop rtl={i18n.language === "ar"} />
          {/* <TawkMessengerReact propertyId="64f03e54b2d3e13950ed188a" widgetId="1h9576uln" /> */}
        </>
      )}
    </>
  );
};

export default App;
