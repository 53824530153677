import moment from "moment";
import { EncryptStorage } from "encrypt-storage";
import { ksaCountryCode } from "./constants";
import { Trans } from "react-i18next";
import ReactGA from "react-ga";
import { logoutUser } from "../redux/reducers/userReducer";
import { removeAllItems } from "../redux/reducers/cartReducer";
import axios from "axios";
import FileDownload from "js-file-download";
import { NotificationHelper } from "../components/shared";
import { persistor } from "../redux/store";

// Encryption of data stored in browser storage
export const encryptStorage = (storageType) => {
  const config = {
    prefix: "@ah-ksa",
    storageType: storageType || "localStorage"
  };

  return new EncryptStorage("secret-key-value", config);
};

export const dispatchStorage = (key, data, storageType) => {
  encryptStorage(storageType).setItem(key, data);
};

export const loadStorage = (key, storageType) =>
  encryptStorage(storageType).getItem(key) || JSON.parse(localStorage.getItem(key));

export const deleteStorage = (key, storageType) => encryptStorage(storageType).removeItem(key);

export const userLogout = async (dispatch) => {
  dispatch(logoutUser());
  dispatch(removeAllItems());
  await persistor.flush();

  window.location.reload();
};

export const scrollPageTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth" // for smoothly scrolling
  });
};

export const scrollToSection = (id, gap) => {
  var getMeTo = document.getElementById(id);
  if (getMeTo) {
    const yOffset = gap;
    const y = getMeTo.getBoundingClientRect().top + window.pageYOffset + yOffset;
    // getMeTo.scrollIntoView({ behavior: "smooth" }, false);
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const isLtr = () => {
  const currentLang = localStorage.getItem("i18nextLng");
  return currentLang === "en" || currentLang === "en-US" || currentLang === "en-GB";
};

export const currentLanguage = () => (isLtr() ? "en" : "ar");

export const getFormatedDate = (date, format) => {
  return date && format ? moment(new Date(date)).format(format) : "";
};

export const getDates = (startDate, stopDate) => {
  var start = new Date(startDate);
  var end = new Date(stopDate);
  let holidays_ = [];
  // Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
  while (start <= end) {
    holidays_.push(getFormatedDate(moment(start), "YYYY-MM-DD"));
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return holidays_;
};

// Global: automatically focus on OTP fields to back and forth
export const automateOTPFields = (e, targetDigit, setShowError) => {
  const val = e.target.value;
  // When moving forwards
  if (setShowError) {
    setShowError(false);

    if (!val.length) return;
    e.target.value = Math.max(0, parseInt(val)).toString().slice(0, 1);
    val && targetDigit?.current.focus();
  }
  // When going backwards
  else {
    if ((e.keyCode || e.charCode) == 8) {
      !val.length && targetDigit.current.focus();
    }
  }
};

// Select input text
export const selectInputText = (e) => e.target.select();

// Car brand and model: Other car select condition
export const isOtherCar = (brand, model) => {
  if (brand === "OTHER" || brand === "سيارة أخرى" || model === "OTHER" || model === "سيارة أخرى")
    return true;
};

// Car plate number: Don't allow alphabets and max 4
export const handleCarPlateNum = (e) => {
  if (!e.target.value) return;
  if (e.target.value.length >= 4) document.getElementById("plateChar").focus();

  e.target.value = e.target.value.slice(0, 4).replace(/[^0-9]/g, "");
};

// Car plate characters: Don't allow numbers and max 3
export const handleCarPlateChar = (e) => {
  if (!e.target.value) return;

  let inputValue = e.target.value;
  inputValue = inputValue
    .replace(/[^A-Za-z\u0600-\u06FF\u0750-\u077F]/g, "")
    .split("")
    .join(" ")
    .slice(0, 5)
    .toUpperCase();
  return (e.target.value = inputValue);
};

// Estimation request: Checking for file type object
export const isFile = (file) => file instanceof File;

// Global: Conditon for (1280px - 1025px) and rest screen sizes
export const is150Res = () => window.innerWidth <= 1280 && window.innerWidth > 1024;

// returns true if extension is pdf otherwise false
export const isExtensionPDF = (fileName) => {
  const extension = fileName && fileName.split(".").pop().toLowerCase();
  return extension === "pdf";
};

//remove first 37 digits of file that are coming from S3 Bucket
export const removeFirst37DigitsFromPdfPath = (fileName) => {
  return fileName && fileName.substring(37);
};

// Global: checking for before 2016 key
export const isBefore2016 = (val) =>
  val === "Before 2016" ? <Trans i18nKey="myAccount.before" /> : val;

// Appoitnment, Estimation, My account: getting car nick name
export const createCarNickName = (car) =>
  car?.carNickName?.trim() || car?.username + " " + (car?.otherModel || car?.model);

// Analytics
export const initGA = () => {
  ReactGA.initialize("YOUR_TRACKING_ID"); // Replace 'YOUR_TRACKING_ID' with the actual tracking ID provided by Google Analytics
};

export const logPageView = (location) => {
  ReactGA.set({ page: location.pathname }); // user information here
  ReactGA.pageview(location.pathname + location.search);
};

export const logEvent = (category, action, label) => {
  // Add Google Analytics event tracking code here
  // for live
  // window.gtag("event", action, {
  //   event_category: category,
  //   event_label: label
  // });

  console.log(category, action, label);
};

export const navigationWithEvents = (key, label, to) => {
  logEvent(key, "button click", label);
  if (to) to();
};

export const logException = (description, fatal = false) => {
  ReactGA.exception({ description, fatal });
};

export const downloadPdf = async (pdf, deposit, refund) => {
  const blob = new Blob([pdf], { type: "application/pdf" });

  // Create a download link for the file
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = refund
    ? `Credit-Memo.pdf`
    : deposit
      ? `Deposit-Receipt.pdf`
      : `Final-Invoice.pdf`;
  document.body.appendChild(link);
  link.click();

  // Clean up the object URL after the download is complete
  URL.revokeObjectURL(url);
};

// Cut out text and add (...)
export const truncateText = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

// checks file's extension
export const checkFileExtension = (fileName) => {
  const extension = fileName && fileName.split(".").pop().toLowerCase();
  return extension;
};

// Get search query data from URL
export const getQueryData = (key) => new URL(window.location.href).searchParams.get(key);

// Phone number restriction to 9 only
export const limitizeValue = (e, limit = 9) => {
  if (e.target.value.length === 0) return;
  e.target.value = Number(Math.abs(e.target.value.slice(0, limit)));
};

// Download sever side files
export const downloadFile = async (key) => {
  if (key === undefined) return;
  let originalKey = key;
  // let filename = originalKey;
  // filename = key.slice(37, key.length);
  let filename = originalKey?.split("2442")?.[1] || key.slice(37, key.length);
  const response = await axios.get("/api/util/download", {
    params: { key },
    headers: { imageKey: "xzic2b7Fei1GJlKuQsJcBtIo12qfnJ5W" },
    progressBar: true,
    responseType: "blob"
  });

  let data = [];
  data = response.config.params.key.split("75973");
  if (!data || !data.length) data = response.config.params.key.split("43770");
  filename = filename != undefined && filename != null && filename != "" ? filename : data[1];
  FileDownload(response.data, filename, "application/pdf image/jpeg image/jpg image/png");
};

// Global: create city options
export const getCitiesFromBranches = (branches) => {
  let cities = branches.map((sp) => ({ en: sp.city, ar: sp.cityNameAr }));
  cities = Object.values(
    cities.reduce((acc, item) => {
      acc[item.en] = acc[item.en] || item;
      return acc;
    }, {})
  );
  return cities;
};

// Global: get phone number without KSA country code
export const removeKSACountryCode = (phoneNumber) =>
  Number(phoneNumber?.replace(ksaCountryCode, ""));

// Global: get phone number without KSA country code
export const concatOTPDigits = (digits) =>
  digits.code1.toString() +
  digits.code2.toString() +
  digits.code3.toString() +
  digits.code4.toString();

// Global: check and notify for internet connection
export const internetDetector = () => {
  window.addEventListener("online", () =>
    NotificationHelper.success(<Trans i18nKey="myAccount.online" />)
  );
  window.addEventListener("offline", () =>
    NotificationHelper.error(<Trans i18nKey="myAccount.offline" />)
  );
};

// Global: email format validator
export const emailFormatValidator = (value) => {
  if (!value) return true;
  return /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(value);
};
// Global: remove extra trailing slashes from pathname
export const getLocationPathname = () => window.location.pathname.replace(/\/+$/, "");
