import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUploader } from "react-drag-drop-files";
import uploadImage from "../../images/upload-icon.svg";
import { GET_MEDIA_URL } from "../../config/webService";
import { isFile, isExtensionPDF, removeFirst37DigitsFromPdfPath } from "../../util/common";
import { Icon } from "./";

const FileInput = ({
  name,
  label,
  formProps: { values, setFieldValue, errors = {}, touched = {} },
  icon = false,
  showImage = false,
  loading = false,
  index = -1,
  className = "",
  types = ["JPG", "JPEG", "PNG"]
}) => {
  const { t } = useTranslation();

  const [fileTypeError, setFileTypeError] = useState("");
  const [loader, setLoader] = useState(false);

  const classes = "drag-drop-file " + className;

  const ImageUploadText = ({ image }) => {
    return (
      <>
        {!image || !showImage ? (
          <>
            {icon && <img src={uploadImage} />}

            <h5>
              {types.includes("PDF")
                ? t("estimationRequest.dragAndDropFile")
                : t("estimationRequest.dragAndDropPhotos")}{" "}
              {/* <span>{t("estimationRequest.browsePhoto")}</span> */}
            </h5>
            <p className="img-formats no-general">
              {t("estimationRequest.supportedFormats")} JPEG, JPG, PNG{" "}
              {types.includes("PDF") ? "PDF" : ""}
            </p>

            {loader && (
              <div className="loading">
                <div className="loading__bar"></div>
                <p className="loading__text">{t("estimationRequest.uploadingLoad")}</p>
              </div>
            )}
            {image && !loader && (
              <div className="file-track">
                <p className="m-0">
                  <span className="success">
                    <Icon iconName="check" />{" "}
                    {t("estimationRequest.uploadedFile") + (isFile(image) ? ": " : "")}
                  </span>{" "}
                  <span>{isFile(image) && image.name.slice(0, 20)}</span>
                </p>
              </div>
            )}
          </>
        ) : image && isExtensionPDF(image?.name || image) ? (
          <div className="display-img">
            <p className="m-0">
              <span>{image?.name || removeFirst37DigitsFromPdfPath(image)}</span>
            </p>
            <Icon iconName="pencil" />
          </div>
        ) : (
          <div className="display-img">
            <img
              src={
                isFile(values[name])
                  ? URL.createObjectURL(values[name])
                  : GET_MEDIA_URL + values[name]
              }
            />

            <Icon iconName="pencil" />
          </div>
        )}
      </>
    );
  };

  const handleImageUpload = (file) => {
    if (loading) {
      setLoader(true);
      setTimeout(() => {
        setFieldValue(name, file);
        setFileTypeError("");
        setLoader(false);
      }, 1000);

      return;
    }

    setFieldValue(name, file);
    setFileTypeError("");
  };

  const handleFieldValueChange = (file) => {
    setFieldValue({ name: name, file: file }, index);
    setFileTypeError("");
  };

  return (
    <div className={classes}>
      <FileUploader
        name={name}
        types={types}
        handleChange={(file) =>
          index < 0 ? handleImageUpload(file) : handleFieldValueChange(file)
        }
        onTypeError={(err) => setFileTypeError(err)}>
        <ImageUploadText image={values[name]} />
      </FileUploader>

      {label && <span className="label">{label}</span>}
      {((errors[name] && touched[name]) || fileTypeError) && (
        <span className="red-color">
          {(fileTypeError && t("estimationRequest.fileTypeError")) || t(errors[name])}
        </span>
      )}
    </div>
  );
};

export default React.memo(FileInput);
