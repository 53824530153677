import React from "react";
import { currentLanguage } from "../../util/common";
import { GET_MEDIA_URL } from "../../config/webService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const OnlineContest = () => {
  const lang = currentLanguage();
  const { media } = useSelector((state) => state.content);
  const { t } = useTranslation();

  return (
    <section className="margin">
      <div className="container-fluid">
        <div className="privacy-policy-wrapper">
          <img src={GET_MEDIA_URL + media["aljLogo_" + lang]} width="350px" />
          <h6>{t("onlineContest.heading")}</h6>
          <p>
            <b>{t("onlineContest.para1Title")}</b> {t("onlineContest.para1Text")}
          </p>
          <p>
            <b>{t("onlineContest.para2Title")}</b> {t("onlineContest.para2Text")}
          </p>
          <p>
            <b>{t("onlineContest.para3Title")}</b> {t("onlineContest.para3Text")}
          </p>
          <p>
            <b>{t("onlineContest.para4Title")}</b> {t("onlineContest.para4Text")}
          </p>
          <p>
            <b>{t("onlineContest.para5Title")}</b> {t("onlineContest.para5Text")}
            <ul>
              <li>
                <a href="https://www.twitter.com/autohubksa" target="_blank" rel="noreferrer">
                  {t("onlineContest.para5Bullet1")}
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/autohubksa" target="_blank" rel="noreferrer">
                  {t("onlineContest.para5Bullet2")}
                </a>
              </li>
            </ul>
          </p>
          <p>
            <b>{t("onlineContest.para6Title")}</b> {t("onlineContest.para6Text")}
          </p>
          <p>
            <b>{t("onlineContest.para7Title")}</b> 
            <ul>
              <li>{t("onlineContest.para7Bullet1")}</li>
              <li>{t("onlineContest.para7Bullet2")}</li>
              <li>{t("onlineContest.para7Bullet3")}</li>
              <li>{t("onlineContest.para7Bullet4")}</li>
            </ul>
          </p>
          <p>
            <b>{t("onlineContest.para8Title")}</b> {t("onlineContest.para8Text")}
          </p>
          <p>
            <b>{t("onlineContest.para9Title")}</b> {t("onlineContest.para9Text")}
          </p>
          <p>
            <b>{t("onlineContest.para10Title")}</b> {t("onlineContest.para10Text")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default OnlineContest;
