import axios from "axios";
import {
  API_GET_AUTOHUB_SERVICES,
  API_GET_AUTOHUB_SERVICE_CATEGORIES,
  API_GET_SERVICE_BY_ID,
  API_GET_SETTINGS
} from "../config/webService";
import BaseService from "../config/baseService";

class AutohubServices extends BaseService {
  async getSevriceCategories() {
    const result = await axios.get(API_GET_AUTOHUB_SERVICE_CATEGORIES);
    return result && result.data?.response;
  }

  async getServices(filterKey, categoryId) {
    const result = await axios.get(API_GET_AUTOHUB_SERVICES, {
      params: {
        filter: filterKey,
        categoryId
      }
    });
    return result?.data?.response;
  }

  async getServicesById(id, fromAppointment = false) {
    let key = id ? `id=${id}&fromWeb=${true}` : null;
    key = key && fromAppointment ? `${key}&fromAppointment=${fromAppointment}` : key;
    const result = await axios.get(`${API_GET_SERVICE_BY_ID}?${key}`);
    return result?.data?.response;
  }

  async getSettings() {
    const result = await axios.get(API_GET_SETTINGS);
    return result?.data;
  }
}

export default new AutohubServices();
