import { useState, useEffect, useCallback } from "react";
import CampaignService from "../services/campaign";
import SubscriptionService from "../services/subscriptionService";

const useOffer = () => {
  const [loading, setLoading] = useState(false);
  const [specialOffers, setSpecialOffers] = useState([]);
  const [everydayDeals, setEverydayDeals] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    getCampaignData();
    getSubscriptionsData();
  }, []);

  const getCampaignData = async () => {
    setLoading(true);
    try {
      const campaigns = await CampaignService.getCampaign("");
      if (campaigns) filterOffer(campaigns);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptionsData = async () => {
    setLoading(true);
    try {
      const subscriptionsResponse = await SubscriptionService.getSubscriptionList();
      setSubscriptions(subscriptionsResponse);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const filterOffer = useCallback((data) => {
    const specialOffers = data.filter((offer) => offer?.offerType === "specialOffer");
    const everydayDeals = data.filter((offer) => offer?.offerType === "everydayDeal");

    setSpecialOffers(specialOffers);
    setEverydayDeals(everydayDeals);
  }, []);

  return { loading, specialOffers, everydayDeals, subscriptions };
};

export default useOffer;
