import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { logoSet } from "../../util/constants";
import { currentLanguage, logEvent } from "../../util/common";
import { Icon } from "./";
import { useSelector } from "react-redux";
import { useAppNavigation } from "../../hooks";

const Footer = () => {
  const { t } = useTranslation();
  const pages = useSelector((state) => state.content.pages) || [];
  const { localeRoutes } = useAppNavigation();

  const lang = currentLanguage();

  const iconClickHandler = (key, path) => {
    logEvent("Footer", "footer_button_click", `${key} Button`);
    window.open(path);
  };

  return (
    <footer className="web-footer">
      <div className="main-wrapper">
        <div className="footer-wrapper row">
          <div className="logo-box col-xl-3 col-lg-4 col-md-4 col-sm-6 logo">
            <Link to={localeRoutes.home[lang]}>
              <img src={logoSet[lang]} width="170" height="50" alt="autohub-logo" />
            </Link>
          </div>
          {(pages.includes("services") || pages.includes("location")) && (
            <div className="links-box col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <span className="group-label">{t("footer.aboutAutohub")}</span>
              <div className="links-wrapper">
                {pages.includes("services") && (
                  <Link
                    to={localeRoutes.services[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Services Link");
                    }}>
                    {t("footer.services")}
                  </Link>
                )}
                {pages.includes("location") && (
                  <Link
                    to={localeRoutes.locations[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Locations Link");
                    }}>
                    {t("footer.locations")}
                  </Link>
                )}
              </div>
            </div>
          )}
          {(pages.includes("sitemap") ||
            pages.includes("requiredDocuments") ||
            pages.includes("termsAndConditions") ||
            pages.includes("accessibilityPolicy") ||
            pages.includes("privacyPolicy")) && (
            <div className="links-box col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <span className="group-label">{t("footer.importantLinks")}</span>
              <div className="links-wrapper">
                {pages.includes("sitemap") && (
                  <Link
                    to={localeRoutes.sitemap[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "SiteMap Link");
                    }}>
                    {t("footer.sitemap")}
                  </Link>
                )}
                {pages.includes("requiredDocuments") && (
                  <Link
                    to={localeRoutes.requiredDocuments[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Required documents Link");
                    }}>
                    {t("footer.requiredDocuments")}
                  </Link>
                )}
                {pages.includes("termsAndConditions") && (
                  <Link
                    to={localeRoutes.termsAndConditions[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Terms and Conditions Link");
                    }}>
                    {t("footer.termsAndConditions")}
                  </Link>
                )}
                {pages.includes("accessibilityPolicy") && (
                  <Link
                    to={localeRoutes.accessibilityPolicy[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Accessibility Policy Link");
                    }}>
                    {t("footer.accessibilityPolicy")}
                  </Link>
                )}
                {pages.includes("privacyPolicy") && (
                  <Link
                    to={localeRoutes.privacyPolicy[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Privacy Policy Link");
                    }}>
                    {t("footer.privacyPolicy")}
                  </Link>
                )}
                {pages.includes("cookiePolicy") && (
                  <Link
                    to={localeRoutes.cookiePolicy[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Cookie Policy Link");
                    }}>
                    {t("footer.cookiePolicy")}
                  </Link>
                )}
              </div>
            </div>
          )}
          <div className="social-icons-box col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <span className="group-label">{t("footer.stayInTouch")}</span>
            <div className="footer-social-wrapper">
              <span
                className="social-icon facebook"
                onClick={() => iconClickHandler("Facebook", "https://www.facebook.com/autohubksa")}>
                <Icon iconName="facebook" />
              </span>
              <span
                className="social-icon twitter"
                onClick={() => iconClickHandler("Twitter", "https://twitter.com/autohubksa")}>
                <Icon iconName="twitter" />
              </span>
              <span
                className="social-icon instagram"
                onClick={() =>
                  iconClickHandler("Instagram", "https://www.instagram.com/autohubksa")
                }>
                <Icon iconName="instagram" />
              </span>
              <span
                className="social-icon linkedin"
                onClick={() =>
                  iconClickHandler("LinkedIn", "https://www.linkedin.com/company/autohubksa")
                }>
                <Icon iconName="linkedIn" />
              </span>
            </div>
            <div className="contact-us-box">
              <a href="tel:920024420">
                <p>
                  <Icon iconName="phone" />
                  <span className="number">920024420</span>
                </p>
              </a>
            </div>
            {pages.includes("findUs") && (
              <Link
                to={localeRoutes.findus[lang]}
                onClick={() => {
                  logEvent("Footer", "footer_link_click", "Find Us Link");
                }}>
                {t("footer.findUs")}
              </Link>
            )}
          </div>
          {(pages.includes("estimation") ||
            pages.includes("schedule") ||
            pages.includes("track") ||
            pages.includes("eshop")) && (
            <div className="company-box col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <span className="group-label">{t("footer.customerService")}</span>
              <div className="services-wrapper">
                {pages.includes("estimation") && (
                  <Link
                    to={localeRoutes.repairEstimate[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_button_click", "Estimation Button");
                    }}
                    className="book-apnt-btn">
                    {t("footer.estimationRequest")}
                  </Link>
                )}
                {pages.includes("schedule") && (
                  <Link
                    to={localeRoutes.bringIt[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_button_click", "Schedule Appointment Button");
                    }}
                    className="book-apnt-btn  blue-color-btn">
                    {t("footer.bookApointment")}
                  </Link>
                )}
                {pages.includes("track") && (
                  <Link
                    to={localeRoutes.track[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_button_click", "Track Button");
                    }}
                    className="book-apnt-btn">
                    {t("footer.track")}
                  </Link>
                )}
                {pages.includes("eshop") && (
                  <Link
                    to={localeRoutes.eshop[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_button_click", "Shop Button");
                    }}
                    className="book-apnt-btn blue-color-btn">
                    {t("footer.shop")}
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="footer-para-wrapper">
          <hr />
          <div className="footer-para-2-wrapper">
            <p className="footer-para-2">
              {t("footer.paraTwo01")}
              <br />
              {t("footer.paraTwo01-i")}
              {pages.includes("termsAndConditions") && (
                <span>
                  <Link
                    to={localeRoutes.termsAndConditions[lang]}
                    onClick={() => {
                      logEvent("Footer", "footer_link_click", "Last Terms and Conditions Link");
                    }}
                    className="footer-terms-and-conditions">
                    {t("footer.paraTwo02")}
                  </Link>
                </span>
              )}{" "}
              {t("footer.paraTwo03")}
            </p>
            {/* <a
                  className="footer-privacy-policy"
                  onClick={toPrivacyAndPolicy}>
                  {t("footer.privacyPolicy")}
                </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
